<template>
    <div class="main-container">

        <Header />

        <Breadcrumb :items="items" title="Széchenyi 2020" :img="'assets/img/about/aboutus.jpg'"/>

        <!-- <AboutSectionThree /> -->
         <div class="about-section section-space--inner--120">
        <div class="container">
          <div class="row">

          <div class="row mb-30">
            <div class="col-lg-6 col-12 mb-30">
              <div class="row mb-2">
                <h2 class="text-center title-blue">Fuchs Építő Korlátolt Felelősségű Társaság</h2>
              </div>
              <div class="row mb-30 justify-content-center">
                <h2 class="text-center title-blue">GINOP-1.2.8-20-2020-01279</h2>
              </div>
              <p><strong>Kedvezményezett neve:</strong> FUCHS Építő Korlátolt Felelősségű Társaság</p>
              <p><strong>Projekt címe:</strong> A Fuchs Kft. technológia fejlesztése</p>
              <p><strong>Szerződött támogatás összege:</strong> 14.210.000,- Ft</p>
              <p><strong>Támogatás mértéke (%-ban):</strong> 70%</p>
              <p><strong>Projekt tartalmának bemutatása:</strong> A pályázat keretében beszerzésre került egy Floormaster Pro 130 automatikus aljzatbeton lehúzógép. A Floormaster Pro 130 géppel nagy mennyiségben lehet estrich betonpadlózatokat kiváló minőségben lehúzni úgy, hogy a beton simítást automatikusan a gép végzi, a gépen egy lézervevő egység van, a lézer adó egység pedig, az előkészített végleges szintre van beállítva.
<br><br>
Erőfeszítés nélkül a gép automatikusan simítja optimálisra a betont. Amint a gép egy szakasszal végez és egy falhoz ér, automatikusan megáll, kiemeli magát és elfordul, ilyenkor a kezelőnek egy gomb megnyomásával a gépet vissza kell engednie, az előzőleg beállított szintre, az indító gomb megnyomásával folytatja a munkát.
<br><br>
Projekt keretében beszerzett Turposol TM 27-45 betonkeverő és szállítógép piacvezető gépnek számít az építőipar ezen szegmensének, mind az aljzatbeton készítők, mind a szerkezeti beton készítők körében. A gép alapfelszereltségében 0-8mm szemszerkezetű betonkeverékek továbbítására alkalmas. Opcionális tartozékok hozzáadásával egészen a 0-16 mm szemszerkezetű betonkeverékek, továbbítására is alkalmas a gép a felhasználás igénye szerint. A gép keverőrendszere hidraulikus meghajtású, ezért tudja az osztályozatlan sódert is keverni, továbbítani.</p>
              <p><strong>A projekt tényleges befejezési dátuma:</strong> 2021.09.30.</p>
              <p><strong>Projekt azonosító száma:</strong> GINOP-1.2.8-20-2020-01279</p>
            </div>
            <div class="col-lg-6 col-12 mb-30">
               <div><img class="responsive" src="/assets/img/logo/szechenyi-felso-transparent.png" alt=""></div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 col-12 mb-30">
              <div class="row mb-30 justify-content-center">
                <h2 class="text-center title-blue">GINOP-4.1.5-22-2022-00051</h2>
              </div>
              <p><strong>Kedvezményezett neve:</strong> FUCHS Építő Korlátolt Felelősségű Társaság</p>
              <p><strong>Projekt címe:</strong> Fuchs Építő Kft. energiahatékonyság növelését célzó fejlesztés</p>
              <p><strong>Szerződött támogatás összege:</strong> 115.758.139,- Ft</p>
              <p><strong>Támogatás mértéke (%-ban):</strong> 90%</p>
              <p><strong>Projekt tartalmának bemutatása:</strong> 
              A vállalkozás célja a fejlesztéssel, hogy a bérbeadással hasznosítandó csarnoképület energiafelhasználása minimális legyen, üzemeltetése költséghatékonnyá váljon. A tervezett 

fejlesztés a 7751 Szederkény, Pécsi út 34. szám alatt található telephelyen valósul meg.

Korszerűsítés keretében megvalósított tevékenységek:
<br>
<br>
1.) Levegő-víz hőszivattyús rendszer kiépítése
<br>
2.) Komplett fűtési- és/vagy használati melegvíz rendszerek korszerűsítése
<br>
3.) Polisztirolhab lemez függőleges szigetelés
<br>
4.) Szendvicspanel vízszintes szigetelés
<br>
5.) Meglévő régi, elavult beltéri világítási rendszerek korszerűsítése
<br>
6.) Műanyag nyílászárók cseréje
<br>
7.) Polisztirolhab lemez vízszintes szigetelés 
              <p><strong>A projekt tényleges befejezési dátuma:</strong> 2023.06.15.</p>
              <p><strong>Projekt azonosító száma:</strong> GINOP-4.1.5-22-2022-00051</p>
            </div>
            <div class="col-lg-6 col-12 mb-30">
               <div><img class="responsive" src="/assets/img/logo/szechenyi-felso-transparent.png" alt=""></div>
            </div>
            
          </div>

          <div class="row">
            <div class="col-lg-6 col-12 mb-30">
              <div class="row mb-30 justify-content-center">
                <h2 class="text-center title-blue">GINOP-5.3.12-19-2020-00562</h2>
              </div>
              <p><strong>Kedvezményezett neve:</strong> FUCHS Építő Kft.</p>
              <p><strong>Projekt címe:</strong> Foglalkoztatásbővítés a FUCHS Kft.-nél</p>
              <p><strong>Szerződött támogatás összege:</strong> 17.393.040,- Ft</p>
              <p><strong>Támogatás mértéke (%-ban):</strong> 70%</p>
              <p><strong>Projekt tartalmának bemutatása:</strong> 
              <p><strong>A projekt tényleges befejezési dátuma:</strong> 2022.10.31.</p>
              <p><strong>Projekt azonosító száma:</strong> GINOP-5.3.12-19-2020-00562</p>
              <p><strong>Projekt tartalmának bemutatása:</strong>
A FUCHS Építő Kft. 2004-ben alakult. Cégünk fő profilja az építőipari generál kivitelezés. Cégünk meglévő telephelyén lévő épületek jelenleg kizárólag logisztikai és őrzési funkciót töltenek be, mivel
nem alkalmasak a megrendelések teljesítéséhez szükséges (építkezések, felújítások) építőanyagok előzetes előkészítésére (felületkezelések, méretre vágások, ragasztások, lakatos munkák). 
<br><br>
Induló beruházásként saját forrásból előkészítési szakmai munka végzésére alkalmas helyiségek és a munkavállalók számára a telephelyi munkavégzés feltételeit biztosító vizesblokkokat alakítunk ki. Ezen beruházáshoz kapcsolódóan a támogatásból 4 fő új munkavállaló foglalkoztatására lesz lehetőségünk. A 4 fő felvételével és a beruházással jelentősen (alapvetően) megváltozik cégünk termelési munkafolyamata minden munkánk esetén, mivel több -eddig a kivitelezés helyszínén végzett munkafolyamatot a telephelyünkön is elő tudjuk készíteni.
<br><br>
A projekt eredményeként várhatóan a piaci szerepünk megerősödik lokálisan és a Dél-Dunántúli Régióban egyaránt.
                </p>
            </div>
            <div class="col-lg-6 col-12 mb-30">
               <div><img class="responsive" src="/assets/img/logo/szechenyi_szocialis_alap.jpg" alt=""></div>
            </div>
            
          </div>
        </div>
         <!--  <div class="row">
            <div><img class="responsive" src="/assets/img/logo/szechenyi-oldalso.png" alt=""></div>
          </div> -->
          <div class="row">
          <div><img class="responsive" src="/assets/img/logo/szechenyi-koszonet.png" alt=""></div>
          </div>
    </div>
    </div>

        <!-- <Policy :policyData="data.policy" />

        <Experience :experience="data.experience" />

        <FunFact />

        <TeamMember :teamData="data.team" />

        <Testimonial />

        <BrandCarousel addClass="grey-bg" /> -->

        <Footer />

        <OffCanvasMobileMenu />

        <!-- back to top start -->
        <!-- <back-to-top class="scroll-top" bottom="180px">
            <i class="ion-android-arrow-up"></i>
        </back-to-top> -->
        <!-- back to top end -->
    </div>
</template>

<script>

    import Header from '@/components/Header';
    import Breadcrumb from '../components/Breadcrumb'
    import AboutSectionThree from '../components/sections/AboutSectionThree'
    import Policy from '../components/Policy'
    import Experience from '../components/Experience'
    import FunFact from '../components/FunFact'
    import TeamMember from '../components/sections/TeamMember'
    import Testimonial from '../components/Testimonial'
    import BrandCarousel from '../components/BrandCarousel'
    import Footer from '../components/Footer'
    import OffCanvasMobileMenu from '@/components/OffCanvasMobileMenu'

    import data from '../data/about.json'
    export default {
        components: {
            Header,
            Breadcrumb,
            AboutSectionThree,
            Policy,
            Experience,
            FunFact,
            TeamMember,
            Testimonial,
            BrandCarousel,
            Footer,
            OffCanvasMobileMenu
        },
        data() {
            return {
                data,
                items: [
                    {
                        text: 'Home',
                        to: "/"
                    },
                    {
                        text: 'About Us',
                        active: true
                    }
                ]
            }
        },
        metaInfo: {
            title: 'Castro - About Us',
            htmlAttrs: {
                lang: 'en',
                amp: true
            }
        }
    }
</script>

<style scoped>
  .responsive {
  height: auto;
  width: 100%;
  min-height: 150px;
}
.mb-24 {
  margin-bottom: 30px;
}
.mb-30 {
  margin-bottom: 60px;
}
.title-blue {
  color: #1B558C;
}
</style>

